<template>
  <div @click="goHome">
    <img src="https://oss.eggxiaoer.com/web/hermes/404.jpeg" alt="" />
  </div>
</template>

<script>
import { getPermissionInfo } from "@/api/user";

export default {
  name: "notFount",
  data() {
    return {};
  },
  filters: {},
  computed: {},
  watch: {},
  methods: {
    goHome() {
      this.getPermissionInfo();
    },
    getPermissionInfo() {
      getPermissionInfo().then((response) => {
        if (response.success == true) {
          this.$store.commit("setPermissionInfo", response.data);
          let list = [];
          response.data.menus.forEach((v) => {
            if (v.children.length == 0 && v.routeAddress) {
              list.push(v.routeAddress);
            } else if (v.children.length > 0) {
              v.children.forEach((c) => {
                list.push(c.routeAddress);
              });
            }
          });
          console.log(window.location.origin);
          if (list.length > 0) {
            window.location.href = window.location.origin + list[0];
          } else {
            // this.$message({ type: "warning", message: "请联系管理员授权!" });
            this.$notify({
              title: "警告",
              message: "请联系管理员授权,并重新登录！",
              type: "warning",
              duration: 0,
            });
            // window.location.href = window.location.origin + "/404";
          }
        }
      });
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {},
};
</script>

<style lang='scss' scoped>
div {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

 
